import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../images/Sec_Banner.png';
function Top() {
  const userdetails = JSON.parse(localStorage.getItem('userdata'));
  return (
    <div className=''>
        <div className='header-div mobile-view'>
      <div className='container d-flex pt-4 pb-4 w-100' >
        <div className='header-left-divs'>
          <div className='left-div-child'>
            <div className='p-4'>
            <p className='welcome-p'>Wel<span className='labridge-color'>come</span></p>
            <p className='labridge-p'>{userdetails.user.labName}</p>
            <div className='onestop-div d-flex '><div class="buttons-container ">
            <Link to="/order-history" className="custom-link">  <button class="button-arounder1">Reports</button></Link>
</div><div class="buttons-container">
<Link to="/invoice" className="custom-link"> <button class="button-arounder">Invoice</button></Link>
</div></div>

            </div>

          </div>
        </div>
      </div>
    </div>
      <div className=' w-100 desktop-view' >
      <div
  id="carouselExampleAutoplaying"
  className="carousel slide"
  data-bs-ride="carousel"
>
  <div className="carousel-inner">
     
    <div className="carousel-item active">
      <img src={img1} style={{height:"80vh"}} className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src={img1}style={{height:"80vh"}} className="d-block w-100" alt="..." />
    </div>
  </div>
  <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleAutoplaying"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleAutoplaying"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

</div>
      </div>
   
  )
}

export default Top